<template>
    <div class="login-container">
        <div class="login-content">
            <!-- Logo 区域 -->
            <div class="logo-box">
                <img src="//img.t.sinajs.cn/t4/appstyle/expression/ext/normal/50/pcmoren_huaixiao_org.png" alt="滑稽"
                    class="logo">
                <p class="subtitle">欢迎使用</p>
            </div>

            <div class="login-form">
                <van-form @submit="onSubmit">
                    <van-cell-group class="form-group">
                        <van-field v-model="loginForm.userName" type="tel" placeholder="请输入手机号"
                            :rules="[{ required: true, message: '请填写手机号' }]" class="custom-field">
                            <template #left-icon>
                                <van-icon name="phone-o" />
                            </template>
                        </van-field>
                        <van-field v-model="loginForm.password" type="password" placeholder="请输入密码"
                            :rules="[{ required: true, message: '请填写密码' }]" class="custom-field">
                            <template #left-icon>
                                <van-icon name="lock" />
                            </template>
                        </van-field>
                    </van-cell-group>

                    <div class="btn-wrapper">
                        <van-button round block native-type="submit">
                            登录
                        </van-button>
                    </div>
                </van-form>
            </div>
        </div>
    </div>
</template>

<script setup>
import { reactive } from 'vue'
import { Form, Field, CellGroup, Button, Icon, showToast } from 'vant'
import { useRouter } from 'vue-router'
import api from '@/utils/api'

const router = useRouter()
const loginForm = reactive({
    userName: '18436264274',
    password: '123456'
})

const onSubmit = async () => {
    const res = await api.user.login({
        userName: loginForm.userName,
        password: loginForm.password
    })
    .then(res => {
        console.log('完整响应：', res)
        if (res.code == 200) {
            showToast('登录成功')
            localStorage.setItem('token', res.data.token)
            router.push('/chart_menu')
        }
    })
}

</script>

<style scoped>
.login-container {
    min-height: 100vh;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 25vh;
}

.login-content {
    width: 100%;
    padding: 0 32px;
}

.logo-box {
    text-align: center;
    margin-bottom: 48px;
}

.logo {
    width: 72px;
    height: 72px;
    border-radius: 16px;
    animation: bounce 2s infinite;
}

.subtitle {
    margin-top: 16px;
    font-size: 18px;
    color: #333;
    font-weight: 500;
}

@keyframes bounce {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-8px);
    }
}

.form-group {
    background: #f8f9fa;
    border-radius: 12px;
    padding: 4px 0;
}

.custom-field {
    background: transparent;
}

:deep(.van-field) {
    padding: 14px 16px !important;
}

:deep(.van-field__left-icon) {
    margin-right: 10px;
}

:deep(.van-icon) {
    color: #666;
    font-size: 18px;
}

:deep(.van-field__control) {
    color: #333;
}

:deep(.van-field__control::placeholder) {
    color: #999;
}

.btn-wrapper {
    margin-top: 32px;
}

:deep(.van-button) {
    height: 44px;
    font-size: 16px;
    background: #333;
    border: none;
    color: #fff;
    font-weight: 500;
}

:deep(.van-button:active) {
    background: #444;
}
</style>