import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        redirect: '/login'
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/page/login.vue')
    },
    {
        path: '/chart_menu',
        name: 'ChartMenu',
        component: () => import('@/page/chart_menu.vue')
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

// 添加路由守卫
router.beforeEach((to, from, next) => {
    const token = localStorage.getItem('token')
    if (to.path === '/login') {
        next()
    } else if (!token) {
        next('/login')
    } else {
        next()
    }
})

export default router 