import axios from 'axios'
import { showToast } from 'vant'

// 创建 axios 实例
const request = axios.create({
  baseURL: 'https://admin.kachou.net/api', // 设置基础URL
  timeout: 15000, // 请求超时时间
  headers: {
    'Content-Type': 'application/json'
  }
})

// 请求拦截器
request.interceptors.request.use(
  config => {
    // 在发送请求之前做些什么
    const token = localStorage.getItem('token')
    if (token) {
      config.headers['Authorization'] = token
    }
    // 添加请求配置日志
    console.log('请求配置：', {
      url: config.url,
      method: config.method,
      data: config.data,
      headers: config.headers
    })
    return config
  },
  error => {
    // 对请求错误做些什么
    console.error('请求错误：', error)
    return Promise.reject(error)
  }
)

// 响应拦截器
request.interceptors.response.use(
  response => {
    const res = response.data
    // 添加响应数据日志
    console.log('响应数据：', res)
    
    if (res.code === 200 || res.code === 0) {
      return res
    } else {
      console.log('响应失败：', res)  // 添加失败响应日志
      showToast({
        message: res.message || '请求失败',
        type: 'fail'
      })
      return Promise.reject(new Error(res.message || '请求失败'))
    }
  },
  error => {
    let message = '网络错误'
    
    if (error.response) {
      // 添加详细的错误日志
      console.error('错误响应：', {
        status: error.response.status,
        data: error.response.data,
        headers: error.response.headers
      })
      switch (error.response.status) {
        case 401:
          message = '未授权，请重新登录'
          // 可以在这里处理登录过期的逻辑
          localStorage.removeItem('token')
          // router.push('/login')
          break
        case 403:
          message = '拒绝访问'
          break
        case 404:
          message = '请求错误，未找到该资源'
          break
        case 500:
          message = '服务器错误'
          break
        default:
          message = error.response.data.message || '网络错误'
      }
    }
    
    showToast({
      message,
      type: 'fail'
    })
    return Promise.reject(error)
  }
)

// 封装常用的请求方法
export const http = {
  get(url, params) {
    return request({
      url,
      method: 'get',
      params
    })
  },
  
  post(url, data) {
    return request({
      url,
      method: 'post',
      data
    })
  },
  
  put(url, data) {
    return request({
      url,
      method: 'put',
      data
    })
  },
  
  delete(url, data) {
    return request({
      url,
      method: 'delete',
      data
    })
  }
}

export default request 