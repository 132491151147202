import { createApp } from 'vue'
import App from './App.vue'
import router from './router'  // 导入路由配置
import 'vant/lib/index.css'

import {
    Form,
    Field,
    CellGroup,
    Button,
    NavBar,
    Toast,
    Icon,
    Popup,
    Tag,
    Tab,
    Tabs
} from 'vant'

const app = createApp(App)

// 注册路由
app.use(router)

// 注册 vant 组件
app.use(Form)
app.use(Field)
app.use(CellGroup)
app.use(Button)
app.use(NavBar)
app.use(Toast)
app.use(Icon)
app.use(Popup)
app.use(Tag)
app.use(Tab)
app.use(Tabs)

app.mount('#app')
