import { http } from './request'

export const api = {
  // 用户相关接口
  user: {
    login: (data) => http.post('/admin/login', data),
  },
  
  //获取号易商品
  product: {
    getHyProduct: (params) => http.get('/admin/goods/api/page', params),
  },
  //获取卡酬商品
  card: {
    getCardProduct: (data) => http.post('/admin/goods/phone_number/query', data),
  }
}

export default api 